import React from 'react';
var morning= "10.30AM - 12.00PM";
var afternoon = "1.00PM - 2.30PM";
var evening1 = "7.00PM - 8.30PM";
var evening2 = "7.30PM - 9.00PM";
var evening3 = "7.00PM - 9.00PM";
var evening4 = "4.00PM - 5.30PM";

const TimeTable = 
      <div className="sessionTimes">
           <table>
                 <tr>
                        <th></th>
                        <th>Morning</th>
                        <th>Afternoon</th>
                        <th>Evening</th>
                 </tr>
                 <tr>
                        <td>Monday</td>
                        <td className="colspan" colspan="3">Express Sessions</td>
                 </tr>
                 <tr>
                        <td>Tuesday</td>
                        <td>{morning}</td>
                        <td>{afternoon}</td>
                        <td>{evening1}</td>
                 </tr>
                 <tr>
                        <td>Wednesday</td>
                        <td className="colspan" colspan="2">Express Sessions</td>
                        <td>{evening2}</td>
                 </tr>
                 <tr>
                        <td>Thursday</td>
                        <td>{morning}</td>
                        <td>{afternoon}</td>
                        <td></td>
                 </tr>
                 <tr>
                        <td>Friday</td>
                        <td>{morning}</td>
                        <td>{afternoon}</td>
                        <td>{evening3}</td>
                 </tr>
                 <tr>
                        <td colspan="4">&nbsp;</td>
                 </tr>
                 <tr>
                        <td>Saturday</td>
                        <td></td>
                        <td>{afternoon}</td>
                        <td>{evening3}</td>
                 </tr>
                 <tr>
                        <td>Sunday</td>
                        <td>{morning}</td>
                        <td>{afternoon}</td>
                        <td>{evening4}</td>
                 </tr>
            </table> 
      </div>;

export default TimeTable;