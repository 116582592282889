import React from "react";
import Fade from 'react-reveal/Fade';

export default function SectionParties({ 
    title, 
    messageTitle, 
    message, 
    message2,
    price,
    dark, 
    id 
}) {
  return (
    <div className={"section" + (dark ? " section-dark" : "")} id={id}>
      <div className="section-content">
        <Fade bottom>
          <h1>{title}</h1><br/>   
          <p>
            <span>{messageTitle}</span><br/><br/>
            {message}<br/><br/>
            {message2}
          </p>
          <div className="line-dark"/>
          <h3><span>{price} </span>Per Child</h3>
          <p2>
              Minimum booking of 8 children<br/>
              For ages 12 & under only<br/>
              Requires a minimum deposit fee
          </p2>
          <div className="line-dark"/>
          <p3>
              To <span>book</span> call us on 55399899
          </p3>
        </Fade>
      </div>
    </div>
  );
}