import React from "react";
import Fade from 'react-reveal/Fade';

export default function SectionLearn({ 
    price,
    price2,
    weekdaytime,
    weekdaytime2,
    weekendtime,
    weekendtime2,
    dark, 
    id 
}) {

  return (
    <div className={"section" + (dark ? " section-dark" : "")} id={id}>
      <div className="section-content">
        <Fade bottom>
          <h1>Learn to Skate</h1><br/>   
          <p>
            <span>Information</span><br/><br/>
              Iceland offers a range of skating lessons from group to private for both adults and the kids!<br/><br/>
              We also boast the only former Olympic Figure Skater in QLD, Mrs Sharon Burley-Sullivan, who directs and coaches our Skating
              School.<br/><br/>
              Skating School consists of recreational skating time and a 30-minute group lesson, where skaters are divided into group depending
              on their skill level.<br/>
            </p>
            <div className="line"/>
            <h3><span>{price}</span> Children &emsp;&emsp;<span>{price2} </span>Adults </h3>
            <p2>
              Includes <span>30-minute group lesson</span> and recreational skating time.<br/>
            </p2>
            <div className="line"/>
            <div class="learntimes">
                <div class="learntimes top">
                    <h3>Monday, Wednesday</h3><br/>
                    <h4><span>{weekendtime2}</span></h4><br/>
                </div>
                <div class="learntimes bot">
                    <h3>Saturday, Sunday</h3><br/>
                    <h4><span>{weekendtime}</span></h4><br/>
                </div>
            </div>
          <div className="line"/>
        </Fade>
      </div>
    </div>
  );
}