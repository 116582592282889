import React from 'react';
import { Link, animateScroll as scroll } from "react-scroll";

const navText = 
      <div>
          <div class="section-text">Get information on our&nbsp;</div>
          <button class="btn">
          <Link
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={0}
                duration={1500}
              >
                <span class="btn-text">session times & prices</span>
          </Link>
          </button>
          <div class="section-bg one"/>
          <div class="section-text">, have your&nbsp;</div>
          <button class="btn">
            <Link
                activeClass="active"
                to="section3"
                spy={true}
                smooth={true}
                offset={0}
                duration={2000}
              >
                <span class="btn-text">child's party</span>
            </Link>
          </button>
          <div class="section-bg two"/>
          <div class="section-text">at iceland, check out our&nbsp;</div>
          <button class="btn">
            <Link
                activeClass="active"
                to="section4"
                spy={true}
                smooth={true}
                offset={0}
                duration={2500}
              >
              <span class="btn-text">learn to skate</span>
            </Link>
          </button>
          <div class="section-bg three"/>
          <div class="section-text">&&nbsp;</div>
          <button class="btn">
            <Link
                activeClass="active"
                to="section5"
                spy={true}
                smooth={true}
                offset={0}
                duration={3000}
              >
                <span class="btn-text">ice hockey</span>
            </Link>
          </button>
          <div class="section-bg four"/>
          <div class="section-text">group classes, all this on the&nbsp;</div>
          <button class="btn">
            <Link
                activeClass="active"
                to="section6"
                spy={true}
                smooth={true}
                offset={0}
                duration={4000}
              >
              <span class="btn-text">Gold Coast</span>
            </Link>
          </button>
          <div class="section-bg five"/>
          <div class="section-text">.</div>
      </div>;

export default navText;