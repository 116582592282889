import React from "react";
import Fade from 'react-reveal/Fade';

export default function SectionMain({ 
    subtitle, 
    messageTitle, 
    message, 
    message2, 
    dark, 
    id 
}) {
  return (
    <div className={"section" + (dark ? " section-dark" : "")} id={id}>
      
      <div className="section-content">
          <Fade bottom>
            
            <h2>{subtitle}</h2>
          </Fade>
      </div>
    </div>
  );
}