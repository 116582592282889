import React from "react";
import Fade from 'react-reveal/Fade';
import insta from '../Images/instaicon.png';
import face from '../Images/faceicon.png';

export default function SectionLocation({ 
    kidsprice,
    kidstime,
    adultsprice,
    adultstime,
    dark, 
    id 
}) {

  return (
    <div className={"section" + (dark ? " section-dark" : "")} id={id}>
      <div className="section-content">
        <Fade bottom>
            <h1>Our Location</h1><br/>   
            <p>
              <a href="mailto:skate@goldcoasticeland.com.au?subject=Contacted via goldcoasticeland.com.au">skate@goldcoasticeland.com.au</a><br/>
                15 Strathaird Rd Bundall 4217<br/>
                0755399899
            </p><br/>
            
            <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3522.5521906296185!2d153.39968931596383!3d-28.007593946499153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9105303c8c3ca5%3A0x44667bd268b062a3!2sGold%20Coast%20Iceland!5e0!3m2!1sen!2sau!4v1594884818938!5m2!1sen!2sau"></iframe>
            
            <div className="line-dark"/>
            <p3>
              Follow us on Social Media
            </p3>
            <br/><br/>
            <div id="socialmedia">
            
              <a href="https://www.facebook.com/icelandbundall">
              <img
                    src={face}
                    alt="face"
                    className="socialbtn"
                ></img>
              </a>
          
              <a href="https://www.instagram.com/gciceland/">
                <img
                    src={insta}
                    alt="insta"
                    className="socialbtn"
                ></img>
              </a>
                
            </div>
        </Fade>
      </div>
    </div>
  );
}